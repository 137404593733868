import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssetType, SecuritiesRepository, Security } from '@capital-access/common/security';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Contact, Institution, SearchProfileService } from '@capital-access/crm/common';
import { CompositeId } from '@capital-access/profiles/common';

export interface SecuritySuggesterState {
  suggestError: boolean;
  suggestions: Security[] | Institution[] | Contact[];
}

const initialState: SecuritySuggesterState = {
  suggestError: false,
  suggestions: []
};

@Injectable()
export class SuggesterStore extends ComponentStore<SecuritySuggesterState> {
  constructor(private securitiesRepository: SecuritiesRepository, private searchProfileService: SearchProfileService) {
    super(initialState);
  }

  readonly getSecuritySuggestions$ = this.select(state => state.suggestions as Security[]);
  readonly getInstitutionSuggestions$ = this.select(state => state.suggestions as Institution[]);
  readonly getContactSuggestions$ = this.select(state => state.suggestions as Contact[]).pipe(
    map(items =>
      items.map(item => ({
        ...item,
        displayName: `${item.firstName} ${item.lastName}`,
        contactUniqueId: new CompositeId(item.prospectingId, item.crmId).toString()
      }))
    )
  );
  readonly getSearchError$ = this.select(state => state.suggestError);

  // effects
  public readonly suggestSecurities = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      switchMap(searchText => {
        return this.securitiesRepository
          .searchSecurities({ searchText, take: 20, skip: 0, assetTypes: [AssetType.Equity] })
          .pipe(
            tapResponse(
              suggestions => this.patchState({ suggestError: false, suggestions }),
              _ => this.patchState({ suggestError: true, suggestions: [] })
            )
          );
      })
    )
  );

  public readonly suggestInstitutions = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      switchMap(searchText =>
        this.searchProfileService.searchInstitutions(searchText).pipe(
          tapResponse(
            ({ institutions }) => this.patchState({ suggestError: false, suggestions: institutions }),
            _ => this.patchState({ suggestError: true, suggestions: [] })
          )
        )
      )
    )
  );

  public readonly suggestContacts = this.effect((searchText$: Observable<string>) =>
    searchText$.pipe(
      switchMap(searchText =>
        this.searchProfileService.searchContacts(searchText).pipe(
          tapResponse(
            ({ contacts }) => this.patchState({ suggestError: false, suggestions: contacts }),
            _ => this.patchState({ suggestError: true, suggestions: [] })
          )
        )
      )
    )
  );
}
