import { CiqEventType } from './ciq-event-type.enum';
import { EventTypeViewModel } from './ciq-event-type-view.model';

export const eventTypes = [
  {
    localizationKey: 'eventTypeConferences',
    key: CiqEventType[CiqEventType.Conferences],
    value: CiqEventType.Conferences
  },

  {
    localizationKey: 'eventTypeEarningsCalls',
    key: CiqEventType[CiqEventType.EarningsCalls],
    value: CiqEventType.EarningsCalls
  },

  {
    localizationKey: 'eventTypeAnalystInvestorDay',
    key: CiqEventType[CiqEventType.AnalystInvestorDay],
    value: CiqEventType.AnalystInvestorDay
  },

  {
    localizationKey: 'eventTypeMA',
    key: CiqEventType[CiqEventType.MA],
    value: CiqEventType.MA
  }
] as EventTypeViewModel[];

export const eventTypesMap = new Map(eventTypes.map(t => [t.value, t]));
