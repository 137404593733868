import { AfterViewInit, Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColumnBase, GridComponent } from '@progress/kendo-angular-grid';
import { fromEvent, of, Subscription } from 'rxjs';
import { debounceTime, filter, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { isColumnSticky } from '../../utils';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'kendo-grid'
})
export class FireflySharedGridDirective implements OnInit, AfterViewInit, OnDestroy {
  private scrollSub$ = Subscription.EMPTY;
  private columnReorderSub$ = Subscription.EMPTY;

  private grid = inject(GridComponent, { self: true });
  private store = inject(Store, { optional: true });

  ngOnInit() {
    if (this.grid.reorderable) this.handleReordering();

    if (window.matchMedia(`(hover: hover) and (pointer: fine)`).matches || !this.grid.scrollBottom.observed) return;
    requestAnimationFrame(() => this.handleScroll());
  }

  ngAfterViewInit() {
    if (this.grid?.columns && this.grid.reorderable) this.lockReorderingForStickyColumns();
  }

  ngOnDestroy() {
    this.scrollSub$.unsubscribe();
    this.columnReorderSub$.unsubscribe();
  }

  private handleReordering() {
    this.columnReorderSub$ = this.grid.columnReorder.subscribe($event => {
      const { newIndex } = $event;
      const column = this.getColumnByIndex(newIndex, this.grid);
      if (!column?.reorderable) $event.preventDefault();
    });
  }

  private handleScroll() {
    const scrollElement = document.querySelector('.ca-content-wrapper');

    if (!scrollElement) return;

    this.scrollSub$ = fromEvent(scrollElement!, 'scroll', { capture: true })
      .pipe(
        debounceTime(100),
        withLatestFrom(this.store?.select(getFeatureToggle('mobile-scroll-behavior-CPD-2028')) || of(true)),
        filter(
          ([e, enabled]) => enabled && scrollElement === e.target && this.elementScrolledToBottom(e.target as Element)
        )
      )
      .subscribe(() => {
        this.grid.scrollBottom.emit();
      });
  }

  private elementScrolledToBottom(el: Element) {
    // extra space to emit event within 100 pixels from end of the page
    const scrollBottomPadding = 100;
    return el && el.scrollHeight - el.scrollTop < el.clientHeight + scrollBottomPadding;
  }

  private lockReorderingForStickyColumns() {
    this.grid.columns.forEach(col => {
      if (isColumnSticky(col) && col.reorderable) col.reorderable = false;
    });
  }

  private getColumnByIndex(index: number, grid: GridComponent): ColumnBase | undefined {
    return grid.columns.find((_, i) => i === index);
  }
}
