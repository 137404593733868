import { Injectable } from '@angular/core';
import { Log } from '@capital-access/common/logging';
import { AppSettingsService } from '@capital-access/common/settings';
import { brandingConfig, fallBackTheme } from '../models/branding';

@Injectable({ providedIn: 'root' })
export class ThemingService {
  stylesHashes: { [key: string]: string } = {};
  defaultStylesLink!: HTMLStyleElement | null;
  defaultStylesHref!: string;

  setTheme(theme: string) {
    this.removeDefaultThemeOnLoad();
    this.setDefaultThemeOnError(theme);
    this.setBrandStyles(theme);
  }

  setSizeIncrease() {
    document.documentElement.classList.add('increased-baseline-size');
  }

  setBrandedFavicon(theme: string) {
    const brand = theme.split('-')[0];
    const favicon = document.getElementById('application-favicon')!;
    favicon.setAttribute('href', `assets/${brand}-favicon.png`);
  }

  private removeDefaultThemeOnLoad() {
    this.stylesLink.onload = () => this.defaultStylesLink?.remove();
  }

  private setDefaultThemeOnError(theme: string) {
    this.stylesLink.onerror = () => {
      Log.warn(`Could not load "${theme}" theme. Setting up the default one.`);
      this.stylesLink.setAttribute('href', `${this.defaultStylesHref}`);
      document.body.dataset.branding = fallBackTheme;
      this.setBrandedFavicon(fallBackTheme);
      this.setThemeColor(fallBackTheme);
    };
  }

  private setBrandStyles(theme: string) {
    const hash = this.stylesHashes[theme];
    const href = hash ? `${theme}.${hash}.css` : `${theme}.css`;
    this.stylesLink.setAttribute('href', href);
    document.body.dataset.branding = theme;
    this.setThemeColor(theme);
  }

  private setThemeColor(theme: string) {
    const mode = theme.split('-')[1];
    const themeColorMetaTags = document.head.querySelectorAll('[name="theme-color"]');
    if (themeColorMetaTags.length > 1) {
      Array.from(themeColorMetaTags).forEach(tag => {
        const colorScheme = tag.getAttribute('media');
        if (colorScheme?.includes(mode)) {
          tag.removeAttribute('media');
          return;
        } else {
          tag.remove();
        }
      });
    } else {
      const themeColor = brandingConfig.snp.getThemeColor(mode);
      themeColorMetaTags[0].setAttribute('content', themeColor);
    }
  }

  private get stylesLink(): HTMLElement {
    return document.getElementById('application-branding')!;
  }

  constructor(private settingsService: AppSettingsService) {
    const settings = this.settingsService.getSettings<{ stylesHashes: { [key: string]: string } }>();
    this.defaultStylesLink = document.head.querySelector(`[href^="${fallBackTheme}"]`);
    this.defaultStylesHref = this.defaultStylesLink?.getAttribute('href') ?? `${fallBackTheme}.css`;
    this.stylesHashes = settings.stylesHashes ?? {};
  }
}
