<div class="position-relative w-max-content">
  <i
    *ngIf="isDataAvailable"
    [style.left]="arrowPosition + '%'"
    class="f-i f-i-sm f-i-caret-down f-bar-arrow-pointer"
    data-automation-id="arrow-position"
  ></i>
  <div class="d-flex mb-2" #barWrapper [style.gap]="gapWidth + 'px'" data-automation-id="scale-chart-bar">
    <div class="scale-chart-bar"></div>
    <div class="scale-chart-bar"></div>
    <div class="scale-chart-bar"></div>
    <div class="scale-chart-bar"></div>
    <div class="scale-chart-bar"></div>
  </div>
</div>
<div class="body-30">
  <span class="text-muted me-1">Level of activist concern:</span
  ><span [ngClass]="[isDataAvailable ? 'fw-bold' : 'text-muted']">{{ barTitle }}</span>
  <a *ngIf="showLearnMore" class="link link-primary ms-2" (click)="learnMore.emit()">
    {{ learnMoreTitle }}
  </a>
</div>
