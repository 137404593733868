import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComplexListData } from '../../models/complex-list-data.model';
import type { SuggesterValidation } from '../../models/suggester-validation.model';

@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class FireflyBaseSuggesterInputs {
  protected _minLength = 3;
  protected _idPath: string | null = null;

  @Input() placeholder = '';
  @Input() maxResultsCount = 5;
  @Input() showViewMore = false;
  @Input() items: unknown[] = [];
  @Input() valuePath = '';
  @Input() idField = 'id';
  @Input() linkPath = 'link';
  @Input() categoryPath = '';
  @Input() disabledPath = 'disabled';
  @Input() disabled = false;
  @Input() showError = false;
  @Input() debounceTime = 400;
  @Input() customFilterMode = false;
  @Input() showContactSupportLink = true;
  @Input() lazyLoadPredefinedSet: boolean = true;
  @Input() showPredefinedSet = false;
  @Input() isOutlineInput = true;
  @Input() placement = 'bottom top';
  @Input() dropdownContainer: 'body' | null = null;
  @Input() validation?: SuggesterValidation;
  @Input() inputValue = new FormControl({ value: '', disabled: this.disabled });
  @Input() clearUnmatchedQueryOnBlur = true;
  @Input() filterListOnFirstInteraction = false;
  @Input() isRequired = false;
  @Input() dropdownCssClass = '';
  @Input() listCssClass = '';
  @Input() set minLength(value: number) {
    this._minLength = value;
  }
  get minLength() {
    return this.showPredefinedSet ? 1 : this._minLength;
  }
  @Input() set idPath(value: string) {
    this._idPath = value;
  }
  get idPath() {
    return this._idPath ?? this.valuePath;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select = new EventEmitter<unknown | ComplexListData>();
  @Output() updateSearch = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset = new EventEmitter<void>();
  @Output() contactSupport = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus = new EventEmitter<void>();
  @Output() viewMore = new EventEmitter<void>();
  @Output() outclick = new EventEmitter<void>();
}
