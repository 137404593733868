import { Injectable } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { TranslocoEvents, TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { getUserSettingsLanguage } from '@capital-access/common/globalization';
import { loadUserProfileSuccess, saveSettingsSuccess } from '@capital-access/common/user';
import { LocalizationService } from '../services/localization.service';
import { languageChange, localizationLoadFail, localizationLoadSuccess } from './localization.actions';

@Injectable()
export class CommonLocalizationEffects {
  constructor(
    private transloco: TranslocoService,
    private localizationService: LocalizationService,
    private store: Store,
    private actions$: Actions
  ) {}

  profileLoadingSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserProfileSuccess),
        withLatestFrom(this.store.select(getUserSettingsLanguage)),
        tap(([, language]) => this.localizationService.setActiveLang(language))
      ),
    { dispatch: false }
  );

  reloadOnPreferencesSave$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSettingsSuccess),
        withLatestFrom(this.store.select(getUserSettingsLanguage)),
        map(([, language]) => {
          const previousLanguage = this.localizationService.getActiveLang();
          return { language, previousLanguage };
        }),
        filter(({ language, previousLanguage }) => language !== previousLanguage),
        tap(({ language }) => {
          this.localizationService.setActiveLang(language);
        }),
        map(({ language, previousLanguage }) =>
          languageChange({ previousLanguage: previousLanguage, currentLanguage: language })
        )
      ),
    { dispatch: true }
  );

  localization$ = createEffect(() =>
    this.transloco.events$.pipe(
      map(({ type, payload }: TranslocoEvents) =>
        type === 'translationLoadSuccess' ? localizationLoadSuccess({ payload }) : localizationLoadFail({ payload })
      )
    )
  );
}
