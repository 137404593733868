<div
  ngbDropdown
  #dropdown="ngbDropdown"
  class="single-suggester"
  [placement]="placement"
  [container]="modalIsOpen ? null : dropdownContainer"
  [autoClose]="modalIsOpen ? false : 'outside'"
  (openChange)="suggesterManager.onOpenChange($event)"
>
  <div
    #anchor
    ngbDropdownAnchor
    class="input-group-right-icon position-relative"
    [class.is-invalid]="requiredAndInvalid"
    [ngClass]="[validationClass]"
  >
    <input
      #inputElement
      type="text"
      class="form-control"
      [class.is-invalid]="requiredAndInvalid"
      [ngClass]="[inputClass, validationClass]"
      [placeholder]="placeholder"
      [formControl]="inputValue"
      (focus)="onFocus()"
      (select)="$event.stopPropagation()"
      (blur)="suggesterManager.onBlur()"
      (click)="openMobileModal()"
      [focusableItemsContainer]="dropdownMenu"
      autocomplete="new-password"
      fDropdownListFocus
    />
    <i
      tabindex="0"
      class="f-i f-i-close"
      data-automation-id="clear-value"
      [title]="clearTooltip$ | async"
      (keyup.enter)="clear()"
      (click)="clear()"
      *ngIf="suggesterManager.inputControlValue.length && !suggesterManager.showLoader"
    ></i>
    <f-spinner
      *ngIf="suggesterManager.showLoader"
      [spinnerSize]="spinnerSizes.Sm"
      class="position-absolute translate-middle-y top-50 end-0 me-2"
    ></f-spinner>
  </div>

  <div *ngIf="validation?.message" [ngClass]="validationMessageClass">{{ validation?.message }}</div>
  <div *ngIf="requiredAndInvalid" class="invalid-feedback">
    {{ requiredErrorMessage$ | async }}
  </div>

  <div #dropdownMenu ngbDropdownMenu [fDropdownWidth]="anchor" [dropdown]="dropdown" [ngClass]="dropdownCssClass">
    <f-list
      #resultsList
      fSuggesterList
      [dropdownMaxHeight]="dropdownMaxHeight"
      [ngClass]="[listCssClass, categoryClass]"
    >
      <ng-container *ngIf="suggesterManager.showList">
        <ng-container *ngFor="let category of listData.groupedItems; let lastGroup = last">
          <f-list-item
            [withHover]="false"
            class="category-name"
            [attr.category]="category?.name"
            *ngIf="category?.name && category.value.length"
            [ngClass]="{ 'heading-20 border-bottom': category?.name && category.value.length }"
          >
            {{ category.name }}
          </f-list-item>

          <f-list-item
            tabindex="0"
            ngbDropdownItem
            *ngFor="let item of category.value; let firstItem = first; let lastItem = last"
            [attr.category]="category?.name"
            [disabled]="item | fIsDisabledItem : disabledPath"
            [ngClass]="{ 'mb-3': category?.name && lastItem && !lastGroup }"
            [class.active]="modalIsOpen && isActive(item | fNestedValue : idPath)"
            (click)="onElementClick(item)"
            (keydown.enter)="onEnter(item, $event)"
          >
            <f-suggester-list-item
              [item]="$any(item)"
              [valuePath]="valuePath"
              [isSingleListItem]="firstItem && lastItem"
              [suggesterItemTemplate]="suggesterItemTemplate"
              [inputValue]="suggesterManager.inputControlValue"
              class="d-block text-truncate"
            ></f-suggester-list-item>
          </f-list-item>
        </ng-container>
      </ng-container>

      <f-list-status-items
        [showError]="showError"
        [showViewMore]="showViewMore"
        [showContactSupportLink]="showContactSupportLink"
        [showLoader]="suggesterManager.showLoader"
        [showNoData]="suggesterManager.showNoData"
        [showNoResults]="suggesterManager.showNoResults"
        [noResultsTemplate]="noResultsTemplate"
        [loaderTemplate]="loaderTemplate"
        [errorTemplate]="errorTemplate"
        [noDataTemplate]="noDataTemplate"
        [viewMoreTemplate]="viewMoreTemplate"
        [footerTemplate]="footerTemplate"
        (viewMore)="suggesterManager.onViewMore()"
        (contactSupport)="suggesterManager.onContactSupport()"
      ></f-list-status-items>
    </f-list>

    <div #resultsFooter *ngIf="footerTemplate" class="single-suggester-footer" [ngClass]="footerTemplateClasses">
      <ng-template [ngTemplateOutlet]="footerTemplate"></ng-template>
    </div>
  </div>
</div>
