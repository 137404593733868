import { createAction, props } from '@ngrx/store';

export const localizationLoadSuccess = createAction(
  '[Localization] Load Success',
  props<{ payload: { scope: string | null; langName: string } }>()
);
export const localizationLoadFail = createAction('[Localization] Load Fail', props<{ payload: unknown }>());
export const languageChange = createAction(
  '[Localization] Language Change',
  props<{ previousLanguage: string; currentLanguage: string }>()
);
