import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  createProfileReportFinished,
  openParticipantProfileReportDrawer,
  openProfileReportDrawer
} from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { DrawerOutput, DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import { ProfileType } from '@capital-access/profiles/common';
import { LOCALIZATION_KEY } from '../../constants';
import { getSavedSections } from '../../core/utils/section-settings.utils';
import { ProfileReportDrawerComponent } from '../components/profile-report-drawer/profile-report-drawer.component';
import { CreateProfileReportDto } from '../models/profile-report.models';
import { getConditionalReportSections, getDefaultSections } from '../models/profile-report-sections';

@Injectable()
export class ProfileReportDrawerEffects {
  openDrawer$ = createEffect(() => this.actions$.pipe(ofType(openProfileReportDrawer), this.openDrawer()));

  openParticipantDrawer$ = createEffect(() =>
    this.actions$.pipe(ofType(openParticipantProfileReportDrawer), this.openDrawer(true))
  );

  private openDrawer(participantReport = false) {
    return (
      source: Observable<{
        profileType: ProfileType;
        profiles: { prId?: number | null; crmId?: number | null; name?: string | null }[];
      }>
    ) =>
      source.pipe(
        withLatestFrom(
          this.localization.getLocalization(LOCALIZATION_KEY),
          this.store.select(getFeatureToggle('profile-report-historical-ownership-SKYL-2377')),
          this.store.select(getFeatureToggle('profile-report-sustainability-fit-ISSYN-2489')),
          this.store.select(getFeatureToggle('reporting-primary-contact-biography-section-cos-6429'))
        ),
        switchMap(
          ([
            action,
            localization,
            isHistoricalOwnershipToggleEnabled,
            isSustainabilityFitToggleEnabled,
            isPrimaryContactBiographyToggleEnabled
          ]) => {
            const conditionalSections = getConditionalReportSections(
              action.profileType !== ProfileType.Contact || isHistoricalOwnershipToggleEnabled,
              action.profileType === ProfileType.Institution && isSustainabilityFitToggleEnabled,
              !participantReport &&
                action.profileType === ProfileType.Institution &&
                isPrimaryContactBiographyToggleEnabled
            );
            const drawer = this.drawerService.openDrawer(
              {
                title: localization[`${action.profileType}DrawerHeader`],
                position: DrawerPosition.Right,
                type: DrawerType.Form,
                dataLayoutId: 'modify-profile-report-drawer'
              },
              ProfileReportDrawerComponent,
              {
                request: CreateProfileReportDto.create(action.profiles, action.profileType),
                filteredSections: getSavedSections(
                  action.profileType,
                  getDefaultSections(action.profileType as ProfileType),
                  conditionalSections
                )
              }
            );

            return drawer.onClose().pipe(
              take(1),
              filter((output: DrawerOutput) => !output || !output['reportInProgress']),
              map(() => createProfileReportFinished())
            );
          }
        )
      );
  }

  constructor(
    private actions$: Actions,
    private localization: LocalizationService,
    private drawerService: FireflyDrawerService,
    private store: Store
  ) {}
}
