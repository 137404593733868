import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyScaleChartComponent } from './scale-chart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyScaleChartComponent],
  exports: [FireflyScaleChartComponent]
})
export class ScaleChartModule {}
