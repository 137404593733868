<f-dropdown
  #parentDropdown
  [hideCaret]="hideCaret"
  [tabbable]="false"
  [disabled]="disabled"
  [btnMaxWidth]="btnMaxWidth"
  [btnStyle]="btnStyle + ' ' + parentDropdownToggleClass"
  [tooltipPlacement]="buttonTooltipPlacement"
  [tooltip]="buttonTooltip"
  [placement]="'bottom-left'"
  [icon]="icon"
  [text]="buttonText"
  [autoClose]="'outside'"
  [preventMobile]="true"
  [menuCssClass]="'dropdown-menu-with-suggester'"
  (dropdownClose)="onDropdownClose()"
>
  <f-spinner
    *ngIf="loading"
    [spinnerSize]="spinnerSizes.Sm"
    rootCssClass="d-flex justify-content-center p-3"
  ></f-spinner>
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="single-suggester"
    [class.d-none]="loading"
    [autoClose]="false"
    [display]="'static'"
    [placement]="placement"
    [container]="modalIsOpen ? null : dropdownContainer"
    (openChange)="onOpenChange($event)"
  >
    <div
      #anchor
      ngbDropdownAnchor
      class="input-group-side-icons"
      [class.d-none]="showPredefinedSet && showError"
      [class.is-invalid]="requiredAndInvalid"
      [ngClass]="[validationClass]"
    >
      <input
        #inputElement
        type="text"
        class="form-control"
        [class.is-invalid]="requiredAndInvalid"
        [ngClass]="[inputClass, validationClass]"
        [placeholder]="placeholder"
        [formControl]="inputValue"
        (focus)="onFocus()"
        (select)="$event.stopPropagation()"
        (blur)="suggesterManager.onBlur()"
        [focusableItemsContainer]="resultsBox"
        autocomplete="new-password"
        fDropdownListFocus
      />
      <i tabindex="-1" class="f-i f-i-search"></i>
      <i
        tabindex="0"
        class="f-i f-i-close"
        data-automation-id="clear-value"
        [title]="clearTooltip$ | async"
        [class.d-none]="!(suggesterManager.inputControlValue.length && !suggesterManager.showLoader)"
        (keyup.enter)="clear()"
        (click)="clear()"
      ></i>
      <f-spinner
        *ngIf="suggesterManager.showLoader"
        [spinnerSize]="spinnerSizes.Sm"
        class="position-absolute translate-middle-y top-50 end-0 me-2"
      ></f-spinner>
    </div>

    <div *ngIf="validation?.message" [ngClass]="validationMessageClass">{{ validation?.message }}</div>
    <div *ngIf="requiredAndInvalid" class="invalid-feedback">
      {{ requiredErrorMessage$ | async }}
    </div>

    <div #resultsBox ngbDropdownMenu [fDropdownWidth]="anchor" [dropdown]="dropdown" [ngClass]="dropdownCssClass">
      <f-list
        #resultsList
        fSuggesterList
        [class.p-1]="showPredefinedSet && showError"
        [dropdownMaxHeight]="dropdownMaxHeight"
        [ngClass]="[listCssClass, categoryClass]"
      >
        <ng-container *ngIf="suggesterManager.showList">
          <ng-container *ngFor="let category of listData.groupedItems; let lastGroup = last">
            <f-list-item
              [withHover]="false"
              class="category-name"
              [attr.category]="category?.name"
              *ngIf="category?.name && category.value.length"
              [ngClass]="{ 'heading-20 border-bottom': category?.name && category.value.length }"
              >{{ category.name }}</f-list-item
            >

            <f-list-item
              ngbDropdownItem
              tabindex="0"
              *ngFor="let item of category.value; let firstItem = first; let lastItem = last"
              [disabled]="item | fIsDisabledItem : disabledPath"
              [attr.category]="category?.name"
              [ngClass]="{ 'mb-3': category?.name && lastItem && !lastGroup }"
              [class.active]="isActive(item | fNestedValue : idPath)"
              (click)="onElementClick(item, $event)"
              (keydown.enter)="onEnter(item, $event)"
            >
              <f-suggester-list-item
                [item]="$any(item)"
                [valuePath]="valuePath"
                [isSingleListItem]="firstItem && lastItem"
                [suggesterItemTemplate]="suggesterItemTemplate"
                [inputValue]="suggesterManager.inputControlValue"
                class="d-block text-truncate"
              ></f-suggester-list-item>
            </f-list-item>
          </ng-container>
        </ng-container>

        <f-list-status-items
          [showError]="showError"
          [showViewMore]="showViewMore"
          [showContactSupportLink]="showContactSupportLink"
          [showLoader]="suggesterManager.showLoader"
          [showNoData]="suggesterManager.showNoData"
          [showNoResults]="suggesterManager.showNoResults"
          [noResultsTemplate]="noResultsTemplate"
          [loaderTemplate]="loaderTemplate"
          [errorTemplate]="errorTemplate"
          [noDataTemplate]="noDataTemplate"
          [viewMoreTemplate]="viewMoreTemplate"
          [footerTemplate]="footerTemplate"
          (viewMore)="suggesterManager.onViewMore()"
          (contactSupport)="onContactSupport()"
        ></f-list-status-items>
      </f-list>

      <div #resultsFooter *ngIf="footerTemplate" [ngClass]="footerTemplateClasses">
        <ng-template [ngTemplateOutlet]="footerTemplate"></ng-template>
      </div>
    </div>
  </div>
</f-dropdown>
