import { NgModule } from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import { GridModule } from '@progress/kendo-angular-grid';
import { FireflySharedGridDirective } from './shared-grid.directive';

@NgModule({
  declarations: [FireflySharedGridDirective],
  exports: [GridModule, FireflySharedGridDirective]
})
export class FireflyGridModule {}
