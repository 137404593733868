<div class="d-flex align-items-center">
  <ng-container *ngIf="!plainView; else plainLogo">
    <div class="d-none d-md-block">
      <a (click)="navigateHome()" class="link link-main text-decoration-none d-flex align-items-center ca-logo-link">
        <div class="f-brand-logo"></div>
        <span *ngIf="showTitle" class="d-none d-lg-inline ms-2 ca-logo-title"> {{ appTitle || title }} </span>
      </a>
    </div>
    <div class="d-block d-md-none">
      <button
        class="btn btn-icon btn-rounded btn-secondary"
        data-menu-button
        caLocalizationScope="navigation"
        [ngbTooltip]="(icon$ | async) ? ('expand' | caLocalize) : ('collapse' | caLocalize)"
        placement="bottom-left"
        (click)="toggleButton()"
      >
        <i class="f-i" [ngClass]="(icon$ | async) ? 'f-i-menu' : 'f-i-close'"></i>
      </button>
    </div>
  </ng-container>
</div>

<ng-template #plainLogo>
  <a (click)="navigateHome()" class="link link-main text-decoration-none d-flex align-items-center ca-logo-link">
    <div class="f-brand-logo flex-grow-0 flex-shrink-0"></div>
    <span *ngIf="showTitle" class="ca-logo-title ms-2 flex-grow-0 flex-shrink-0" [ngClass]="titleCssClass">
      {{ appTitle || title }}
    </span>
  </a>
</ng-template>
