import { DateTime, FixedOffsetZone } from 'luxon';

export function isTimezoneValid(timeZoneName: string) {
  return DateTime.local().setZone(timeZoneName).zone.isValid;
}

export function getLocalTimezone() {
  return DateTime.local().zoneName;
}

export function getLocalTimezoneOffset() {
  return DateTime.local().offset;
}

export function isUtcOffset(timeZoneName: string) {
  return DateTime.local().setZone(timeZoneName).isOffsetFixed;
}

export function getTimeZoneUtcOffset(timeZoneName: string) {
  const zone = getZonedDate(timeZoneName);
  return FixedOffsetZone.instance(zone.offset).name;
}

export function getTimeZoneUtcOffsetValue(date: Date, timeZoneName: string) {
  const zone = getZonedDate(timeZoneName, date);
  return zone.offset;
}

export function getFormattedOffset(offset: number) {
  return FixedOffsetZone.instance(offset).formatOffset(offset, 'short');
}

function getZonedDate(timeZoneName: string, date?: Date) {
  return date ? DateTime.fromJSDate(date).setZone(timeZoneName) : DateTime.local().setZone(timeZoneName);
}
