import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import isEmpty from 'lodash/isEmpty';
import { Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { addActivity, logout, openContactSupportDrawer } from '@capital-access/common/actions';
import { SelectorSecurity } from '@capital-access/common/components';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { ToggleNavItemConfig, UserProfileDropdownItem } from '@capital-access/common/navigation';
import {
  getPrimarySecuritySettings,
  SecuritySelectorProvider,
  setSelectedSecurityId
} from '@capital-access/common/security';
import { mapToRecord } from '@capital-access/common/utils';
import { getBrandingSettings } from '@capital-access/theming';
import { EntityItem } from '../../models/entity-item';
import { CONTACT_SUPPORT_URL, LOGOUT_URL, NAVIGATION_LOCALIZATION_SCOPE } from '../navigation.constants';
import { SharedNavigationItemsService } from '../services/ca-shared-navigation-items.service';

@Component({
  selector: 'ca-top-nav',
  templateUrl: './top-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavComponent {
  @Input() flexibleTopNav = false;

  user$ = this.sharedNavigationItemsService.user$;
  userSettingsOptions$: Observable<UserProfileDropdownItem[]> =
    this.sharedNavigationItemsService.userSettingsOptionsTop$;

  showApplicationTitle$ = this.store.select(getBrandingSettings).pipe(
    withLatestFrom(this.store.select(getFeatureToggle('bny-branding-CPD-2146'))),
    map(([{ brand }, featureEnabled]) => !(brand === 'bny' && featureEnabled))
  );

  toggleSettings: ToggleNavItemConfig = this.sharedNavigationItemsService.toggleSettingsOption[0];

  entityIcon: string = this.sharedNavigationItemsService.entitySettingsIcon;
  entityList: EntityItem[] = this.sharedNavigationItemsService.entitySettingsOptionsTop;

  applicationSecurities$ = this.provider.getSecurities().pipe(
    switchMap(availableSecurities =>
      this.store.select(getPrimarySecuritySettings).pipe(
        withLatestFrom(
          this.localizationService.localize(
            ['availableSecurities', 'pinnedSecurities'],
            {},
            NAVIGATION_LOCALIZATION_SCOPE
          )
        ),
        map(([primarySecurities, [availableSecuritiesTitle, pinnedSecuritiesTitle]]) => {
          const all = mapToRecord(availableSecurities, x => x.id);
          const primary = primarySecurities.primary?.filter(id => !!all[id]).map(x => all[x]) ?? [];

          const availableSecurityItems: SelectorSecurity[] = availableSecurities.map(x => {
            return { ...x, category: availableSecuritiesTitle };
          });

          const pinnedSecurityItems: SelectorSecurity[] = primary.map(x => {
            return { ...x, category: pinnedSecuritiesTitle };
          });

          if (isEmpty(pinnedSecurityItems) && availableSecurityItems.length > 5) {
            pinnedSecurityItems.push({
              id: null,
              name: '',
              ticker: '',
              category: pinnedSecuritiesTitle
            } as unknown as SelectorSecurity);
          }

          return availableSecurityItems.length > 5
            ? [...pinnedSecurityItems, ...availableSecurityItems]
            : availableSecurityItems;
        })
      )
    )
  );

  applicationSelectedSecurity$ = this.provider.getSelectedSecurity();

  constructor(
    private router: Router,
    private store: Store,
    private localizationService: LocalizationService,
    private sharedNavigationItemsService: SharedNavigationItemsService,
    private provider: SecuritySelectorProvider
  ) {}

  onSetSecurities() {
    this.router.navigate(['user', 'settings']);
  }

  onApplicationSecuritySelected(selectedSecurity: { id: number }) {
    this.store.dispatch(setSelectedSecurityId({ selectedSecurityId: selectedSecurity.id }));
  }

  onSurveillanceToggle() {
    this.store.dispatch(this.toggleSettings.action);
  }

  entityItemClick(entityIndex: number): void {
    this.store.dispatch(this.entityList[entityIndex].action);
  }

  userItemClick($event: unknown): void {
    const item = $event as UserProfileDropdownItem;

    if (item.route === CONTACT_SUPPORT_URL) {
      this.store.dispatch(openContactSupportDrawer());
    } else if (item.route === LOGOUT_URL) {
      this.store.dispatch(logout());
    } else {
      this.router.navigate([item.route]);
    }
  }

  onAddActivity(tooltip: NgbTooltip): void {
    if (tooltip.isOpen()) {
      tooltip.close();
    }
    this.store.dispatch(addActivity());
  }

  logout() {
    this.store.dispatch(logout());
  }
}
