import { Pipe, PipeTransform } from '@angular/core';
import { CiqEventType } from '../models/ciq-events/ciq-event-type.enum';
import { eventTypesMap } from '../models/ciq-events/ciq-event-types';

@Pipe({
  name: 'caCiqEventTypeLocalizationKey',
  standalone: true
})
export class CiqEventTypeLocalizationKeyPipe implements PipeTransform {
  transform(typeId: CiqEventType) {
    return eventTypesMap.get(typeId)!.localizationKey;
  }
}
