import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import isNull from 'lodash/isNull';
import { ChartDataEntry, ChartDataSeries } from '../common';
import { FireflyStackedBarLineChartComponent } from '../stacked-bar-line-chart';

@Component({
  selector: 'f-positive-negative-bar-line-chart',
  templateUrl: './positive-negative-bar-line-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyPositiveNegativeBarLineChartComponent extends FireflyStackedBarLineChartComponent {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  @Input() override lineData!: ChartDataSeries[];
  @Input() showPointerLine = false;
  @Input() negativeBarClasses: string[] = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  chartComponent = FireflyPositiveNegativeBarLineChartComponent;

  mapLineDataToDataSeries() {
    this.data = this.data.map((entry, index) => {
      const lineData = this.lineData[index];
      lineData.series.forEach((d, index) => {
        const cssClass = d.cssClass;
        const lineClass = this.lineClasses[index];
        const combinedClasses = [lineClass, cssClass].filter(Boolean).join(' ');
        if (combinedClasses) d.cssClass = combinedClasses;
      });
      return { ...entry, lineData };
    });
  }

  getYDomain(data: ChartDataEntry[] | ChartDataSeries[]): number[] {
    const dataEntry = data as { name: string; value: number; series?: ChartDataEntry[] }[];

    const values = dataEntry
      .map(d => {
        if ('series' in d) {
          return this.getMinAndMaxValues(
            d.series!.map(i => i.value as number | undefined).filter(v => typeof v === 'number') as number[]
          );
        }
        return [d.value];
      })
      .flat();

    return this.getDomainWithPaddings(this.getMinAndMaxValues(values));
  }

  shouldAdjustYScaleDomain(threshold: number) {
    const optionalData = [...this.data].filter(d => !!d.optional);
    if (!optionalData.length) return;
    const maxValues = optionalData.map(i => {
      return Math.max(0, ...(i.series as ChartDataEntry[]).map(i => i.value!).filter(i => !isNull(i)));
    });
    return maxValues.some(value => value > threshold);
  }

  private getMinAndMaxValues(values: number[]) {
    const min = Math.min(0, ...values);
    const max = Math.max(0, ...values);
    return [min, max];
  }
}
