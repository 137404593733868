import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyGridResizingService } from './grid-resizing.service';
import { FireflyKendoDetailsGridInteractiveDirective } from './interactive-kendo-details-grid.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyKendoDetailsGridInteractiveDirective],
  exports: [FireflyKendoDetailsGridInteractiveDirective],
  providers: [FireflyGridResizingService]
})
export class FireflyInteractiveGridModule {}
