import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../../common-components.const';
import { ActivistInvestorType } from './models/activist-investor-type.enum';

@Component({
  selector: 'ca-activist-badge',
  templateUrl: './activist-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivistBadgeComponent implements OnInit {
  @Input() activistType!: ActivistInvestorType;
  activistTypeTooltip$!: Observable<string>;

  localizationScope = COMMON_COMPONENTS_LOCALIZATION_SCOPE;

  constructor(@Optional() @Self() private localizationService: LocalizationService) {}

  ngOnInit() {
    this.activistTypeTooltip$ = this.localizationService
      ? this.localizationService.localize(this.activistType?.toLowerCase(), {}, COMMON_COMPONENTS_LOCALIZATION_SCOPE)
      : of(this.activistType);
  }

  get badgeColor() {
    switch (this.activistType) {
      case ActivistInvestorType.Occasional:
        return 'badge-warning';
      case ActivistInvestorType.Often:
        return 'badge-danger';
      default:
        return '';
    }
  }
}
