import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeFormattingService } from '@capital-access/common/formatting';
import { ProfileRepository, ProfileType } from '@capital-access/profiles/common';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { CreateProfileReportDto } from '../models/profile-report.models';
import { LocalizationService } from '@capital-access/common/localization';
import { LOCALIZATION_KEY } from '../../constants';

@Injectable()
export class ProfileReportNamingService {
  constructor(
    private dateFormatting: DateTimeFormattingService,
    private profileRepository: ProfileRepository,
    private localization: LocalizationService
  ) {}
  public getProfileReportFileName(
    showSrvSource: boolean,
    isSrv: boolean,
    createProfileRequest: CreateProfileReportDto,
    timezone: string
  ): Observable<string> {
    const currentDate = new Date();
    const ownershipSourceKey = isSrv ? 'surveillanceSource' : 'publicSource';

    return combineLatest([
      this.localization.localize(ownershipSourceKey, {}, { scope: LOCALIZATION_KEY }),
      this.dateFormatting.formatDate(currentDate.toISOString(), { zone: timezone }, DEFAULT_DATE_FORMAT),
      this.getProfileSpecificName(createProfileRequest)
    ]).pipe(
      map(([ownershipSource, formattedDate, profileSpecificName]) => {
        const dataSource = showSrvSource ? `- (${ownershipSource}) ` : '';
        return `${profileSpecificName} ${dataSource}- ${formattedDate}`;
      })
    );
  }

  private getProfileSpecificName(createProfileRequest: CreateProfileReportDto): Observable<string> {
    const isSingleProfile = createProfileRequest.profiles.length === 1;

    if (!isSingleProfile) {
      const reportNameKey =
        createProfileRequest.profileType === ProfileType.Institution ? 'investorProfileReport' : 'contactProfileReport';

      return this.localization.localize(reportNameKey, {}, { scope: LOCALIZATION_KEY });
    }
    if (createProfileRequest.profiles[0].name) return of(createProfileRequest.profiles[0].name);

    switch (createProfileRequest.profileType) {
      case ProfileType.Contact: {
        return this.profileRepository.getContactInformation(createProfileRequest.profiles[0].id, ['name']).pipe(
          map(profile => {
            return profile.name!;
          })
        );
      }
      case ProfileType.Institution: {
        return this.profileRepository.getInstitutionInformation(createProfileRequest.profiles[0].id, ['name']).pipe(
          map(profile => {
            return profile.name!;
          })
        );
      }
      default:
        return of('');
    }
  }
}
