import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { tap } from 'rxjs';
import { SideNavigationMap } from '@capital-access/common/navigation';
import { Breakpoint } from '@capital-access/firefly/components';
import { AppNameLocation } from '../../models/app-name-location';
import { SideNavService } from '../side-nav.service';
import { LinkNavItemComponent } from './components/link-nav-item/link-nav-item.component';
import { NAV_TRANSITION_DURATION_FUNC } from './constants';

@Component({
  selector: 'ca-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['side-nav.component.scss'],
  animations: [
    trigger('fadeSimpleItem', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavComponent {
  @Input() flexibleSideNav = false;
  @Input() navigationMap: SideNavigationMap | null = null;
  @Input() appNameLocation: AppNameLocation = AppNameLocation.Top;

  @ViewChildren(LinkNavItemComponent) navItems?: QueryList<LinkNavItemComponent>;
  @ViewChild('sideNavContainer') sideNavContainer?: ElementRef;

  AppNameLocation = AppNameLocation;
  selectedItemId: string | null = null;

  panelExpanded$ = this.sideNavService.panelExpanded$.pipe(
    tap(panelExpanded => {
      if (!panelExpanded) {
        this.sideNavContainer?.nativeElement.scrollIntoView({ block: 'start' });
      }
    })
  );

  get isMobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  constructor(private sideNavService: SideNavService) {}

  toggleExpand(expanded: boolean, event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.sideNavService.togglePanelExpanded();

    if (expanded) {
      if (!this.selectedItemId) {
        this.showActiveItemPanel();
      }
    } else {
      this.hideItemPanel(this.selectedItemId);
    }
  }

  onClickOutside() {
    this.sideNavService.togglePanelExpanded();
    this.hideItemPanel(this.selectedItemId);
  }

  onItemClick(itemId: string) {
    if (this.selectedItemId === itemId) {
      this.hideItemPanel(itemId);
    } else {
      this.showItemPanel(itemId);
    }
  }

  preventClose(e: Event) {
    e.stopPropagation();
  }

  private hideItemPanel(itemId: string | null) {
    if (itemId) {
      this.navItems?.find(i => i.id === itemId)?.hide();

      this.selectedItemId = null;
    }
  }

  private showItemPanel(itemId: string | null) {
    if (itemId) {
      this.navItems?.find(i => i.id === itemId)?.show();
      this.navItems?.find(i => i.id === this.selectedItemId)?.hide();

      this.selectedItemId = itemId;
    }
  }

  private showActiveItemPanel() {
    const activeItem = this.navItems?.find(i => !!i.active);

    if (activeItem) {
      this.selectedItemId = activeItem.id;
      activeItem.show();
    }
  }
}
