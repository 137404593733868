import { NgModule } from '@angular/core';
import { CiqEventRepository } from './ciq-event.repository';
import { EventCompanyDetailsRepository } from './company-details.repository';
import { EventRepository } from './event.repository';
import { CiqEventTypeLocalizationKeyPipe } from './pipes/ciq-event-type-localization.pipe';
import { EventLinkPipe } from './pipes/event-link.pipe';
import { EventTypeLocalizationKeyPipe } from './pipes/event-type-localization.pipe';
import { KendoTodayClassPipe } from './pipes/kendo-today-class.pipe';
import { EngageLinkProvider } from './providers/event-link-provider';

@NgModule({
  imports: [CiqEventTypeLocalizationKeyPipe],
  declarations: [EventLinkPipe, KendoTodayClassPipe, EventTypeLocalizationKeyPipe],
  providers: [EventRepository, EventCompanyDetailsRepository, EngageLinkProvider, CiqEventRepository],
  exports: [EventLinkPipe, KendoTodayClassPipe, EventTypeLocalizationKeyPipe]
})
export class EventsCommonModule {}
