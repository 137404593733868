import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FireflyGridResizingService } from '../interactive';
import { FireflyStickyColumnsDirective } from './sticky-columns.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FireflyStickyColumnsDirective],
  exports: [FireflyStickyColumnsDirective],
  providers: [FireflyGridResizingService]
})
export class FireflyStickyColumnsModule {}
