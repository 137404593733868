import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeFormattingService } from '@capital-access/common/formatting';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { LocalizationService } from '@capital-access/common/localization';
import { LOCALIZATION_KEY } from '../../constants';

@Injectable()
export class FileNameService {
  constructor(private dateFormatting: DateTimeFormattingService, private localization: LocalizationService) {}
  public getDefaultFileName(
    showSrvSource: boolean,
    isSrv: boolean,
    name: string,
    timezone: string
  ): Observable<string> {
    const currentDate = new Date();
    const ownershipSourceKey = isSrv ? 'surveillanceSource' : 'publicSource';
    return combineLatest([
      this.localization.localize(ownershipSourceKey, {}, { scope: LOCALIZATION_KEY }),
      this.dateFormatting.formatDate(currentDate.toISOString(), { zone: timezone }, DEFAULT_DATE_FORMAT)
    ]).pipe(
      map(([ownershipSource, formattedDate]) => {
        const dataSource = showSrvSource ? `- (${ownershipSource}) ` : '';
        return `${name} ${dataSource}- ${formattedDate}`;
      })
    );
  }
}
