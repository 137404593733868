import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { BROWSER_TITLE_FALLBACK_VALUE, TitlePartString } from '@capital-access/common/browser';
import { NAV_TRANSITION_DURATION_FUNC, NAV_TRANSITION_DURATION_FUNC_OPACITY } from '../../constants';

@Component({
  selector: 'ca-bottom-nav-item',
  templateUrl: './bottom-nav-item.component.html',
  styleUrls: ['./bottom-nav-item.component.scss'],
  animations: [
    trigger('fadeWidth', [
      state('void', style({ width: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC)])
    ]),
    trigger('fadeOpacity', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate(NAV_TRANSITION_DURATION_FUNC_OPACITY)])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavItemComponent {
  @Input() mini!: boolean;
  @Input() showApplicationTitle = false;
  @Input() appTitle?: string;

  constructor(@Optional() @Inject(BROWSER_TITLE_FALLBACK_VALUE) public fallbackTitle: TitlePartString) {}

  get currentYear() {
    return new Date().getFullYear();
  }
}
