import { createSelector } from '@ngrx/store';
import capitalize from 'lodash/capitalize';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { createSettingsSelectors } from '@capital-access/common/user';
import {
  brandingConfig,
  BrandingModeSettings,
  fallBackBranding,
  fallBackMode,
  getBrandingKey,
  SupportedBranding
} from '../models/branding';
import { APPLICATION_KEY, BrandingSettings } from '../models/branding';

export const { selectSettings: getBrandingSettings } = createSettingsSelectors(BrandingSettings, APPLICATION_KEY);
export const { selectSettings: getBrandingModeSettings } = createSettingsSelectors(
  BrandingModeSettings,
  getBrandingKey('branding')
);

export const getBranding = createSelector(getBrandingSettings, ({ brand }) => {
  const hasBranding = !!brandingConfig[brand as SupportedBranding];
  return hasBranding ? brand : fallBackBranding;
});

export const getBrandingMode = createSelector(getBrandingModeSettings, ({ mode }) => {
  return mode ?? fallBackMode;
});

export const getSupportedBrandingModes = createSelector(getBranding, brand => {
  return brandingConfig[brand as SupportedBranding].modes.map(mode => ({ title: capitalize(mode), value: mode })) ?? [];
});

export const getTheme = createSelector(
  getBrandingSettings,
  getBrandingModeSettings,
  getFeatureToggle('bny-branding-CPD-2146'),
  getFeatureToggle('bny-dark-mode-CPD-2229'),
  ({ brand }, { mode }, bnyEnabled, bnyDarkEnabled) => {
    const finalBrand = bnyEnabled
      ? brandingConfig[brand as SupportedBranding] ?? brandingConfig[fallBackBranding]
      : brandingConfig[fallBackBranding];

    const finalMode = finalBrand.alias === 'BNY' && !bnyDarkEnabled ? fallBackMode : mode;
    return finalBrand.getTheme(finalMode);
  }
);
