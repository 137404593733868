<f-top-nav [flexibleTopNav]="flexibleTopNav">
  <ng-container fLogoContainer>
    <ca-app-switcher></ca-app-switcher>
    <ca-logo [showTitle]="(showApplicationTitle$ | async)!"></ca-logo>
  </ng-container>
  <ng-container fSelectorContainer>
    <div class="d-flex align-items-center">
      <ca-security-selector
        [securities]="(applicationSecurities$ | async) || []"
        [selected]="(applicationSelectedSecurity$ | async)!"
        (setSecurities)="onSetSecurities()"
        (securityIdSelected)="onApplicationSecuritySelected($event)"
      ></ca-security-selector>
    </div>
  </ng-container>
  <ng-container fToggleContainer>
    <ca-surveillance-toggle
      class="text-truncate"
      *ngIf="toggleSettings.isShown | async"
      [checked]="!!(toggleSettings.value | async)"
      [label]="toggleSettings.localizationKey"
      (toggle)="onSurveillanceToggle()"
    ></ca-surveillance-toggle>
  </ng-container>

  <ng-container fSearchContainer>
    <ng-content></ng-content>
  </ng-container>
  <ng-container fEntityContainer>
    <ca-add-entity-dropdown
      data-automation-id="ca-addressbook-dropdown"
      [icon]="entityIcon"
      [list]="entityList"
      (itemClick)="entityItemClick($event)"
    ></ca-add-entity-dropdown>
  </ng-container>
  <ng-container fActivityContainer>
    <button
      class="btn btn-primary btn-icon"
      caLocalizationScope="navigation"
      [ngbTooltip]="'newActivityTooltip' | caLocalize"
      placement="bottom-right"
      container="body"
      #tooltip="ngbTooltip"
      (click)="onAddActivity(tooltip)"
      data-automation-id="add-activity-button"
    >
      <i class="f-i f-i-calendar-add"></i>
    </button>
  </ng-container>
  <ng-container fUserMenuContainer>
    <f-dropdown-with-initials
      [user]="user$ | async"
      [items]="userSettingsOptions$ | async"
      (itemClick)="userItemClick($event)"
    ></f-dropdown-with-initials>
  </ng-container>
</f-top-nav>
