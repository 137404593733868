import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  QueryList
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { EMPTY, finalize, iif, map, mergeMap, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { Log } from '@capital-access/common/logging';
import { Alert } from '../alerts';
import { FireflyDrawerContent } from '../drawer';
import { DraggableItem, PreventDragArgs } from '../lists';
import { LoadingType } from '../loading-indicators';
import { FireflyMultipleSuggesterComponent } from '../suggesters/components/multiple-suggester/multiple-suggester.component';
import { isColumnSticky } from '../utils';
import { FireflyLocalizationService } from '../utils/localization/firefly-localization.service';
import { ColumnChooserTableSettings, FireflyColumnChooserService } from './column-chooser.service';

@Component({
  selector: 'f-column-chooser-drawer',
  templateUrl: './column-chooser-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyColumnChooserDrawerComponent
  extends FireflyDrawerContent<{
    grid: GridComponent;
    gridId: string;
    initialGridColumns: ColumnComponent[];
    maxSelectedOptionalColumnsCount: number;
  }>
  implements OnInit, OnDestroy
{
  @Input() gridId = '';
  @Input() grid!: GridComponent;
  @Input() initialGridColumns: ColumnComponent[] = [];
  @Input() maxSelectedOptionalColumnsCount = 20;

  @HostBinding('class') class = 'h-100 d-flex flex-column overflow-hidden m-n1 p-1 pb-0';

  alertType = Alert;
  sortingField!: string;
  showSubmitError = false;
  showAddColumnsLink = true;
  sortSub$ = Subscription.EMPTY;
  reorderSub$ = Subscription.EMPTY;
  iconTitleSub$ = Subscription.EMPTY;
  optionalColumns: { title: string }[] = [];
  optionalStagedColumns: ColumnComponent[] = [];
  optionalSelectedColumns: ColumnComponent[] = [];
  optionalColumnsToRemove: ColumnComponent[] = [];
  selectedOptionalColumns: { title: string }[] = [];
  columns: (DraggableItem & ColumnComponent)[] = [];
  optionalColumnsInitialState: ColumnComponent[] = [];
  submitErrorMessage$ = of('Oops… There was a problem saving. Please try again.');
  optionalColumnsLimitReachedMessage$ = of('Selected optional columns limit reached');
  noAdditionalColumnsMessage$ = of('No additional columns to add');
  lastColumnTitle$ = of('At least one column should be selected');
  addAdditionalColumnsTitle$ = of('Add Additional Columns');
  sortedColumnTitle$ = of('Sorted column can’t be turned off');
  stickyColumnTitle$ = of('Sticky columns can’t be turned off or reordered');
  requiredColumnTitle$ = of('Required columns can’t be turned off');
  suggesterPlaceholder$ = of('Select custom field');
  requiredColumnDescription$ = of('Required');
  sortedColumnDescription$ = of('Sorted by');
  stickyColumnDescription$ = of('Sticky');
  selectAllBtnTitle$ = of('Select all');
  clearAllBtnTitle$ = of('Clear all');
  resetBtnTitle$ = of('Reset to Default');
  cancelBtnTitle$ = of('Cancel');
  saveBtnTitle$ = of('Save');
  reset = false;
  dragVisibilityClasses = 'mt-n1 pt-1';

  constructor(
    protected cdr: ChangeDetectorRef,
    private columnChooserService: FireflyColumnChooserService,
    private destroyRef: DestroyRef,
    @Optional() private localizationService: FireflyLocalizationService
  ) {
    super();
  }

  onInjectInputs(inputs: {
    grid: GridComponent;
    gridId: string;
    initialGridColumns: ColumnComponent[];
    maxSelectedOptionalColumnsCount: number;
  }): void {
    this.grid = inputs.grid;
    this.gridId = inputs.gridId;
    this.initialGridColumns = inputs.initialGridColumns;
    this.maxSelectedOptionalColumnsCount = inputs.maxSelectedOptionalColumnsCount;
  }

  get showSelectAllBtn(): boolean {
    return this.columns.some(col => col.hidden);
  }

  get showClearAllBtn(): boolean {
    return this.columns.some(col => !col.hidden);
  }

  get selectedOptionalColumnsCount() {
    return this.columns.filter(col => this.isColumnOptional(col)).length;
  }

  get selectedOptionalColumnsLimitReached() {
    return this.selectedOptionalColumnsCount >= this.maxSelectedOptionalColumnsCount;
  }

  get addOptionalColumnLinkTitle(): Observable<string> {
    return this.selectedOptionalColumnsLimitReached
      ? this.optionalColumnsLimitReachedMessage$
      : !this.optionalColumns.length
      ? this.noAdditionalColumnsMessage$
      : this.addAdditionalColumnsTitle$;
  }

  get listRootCssClass() {
    return `${this.dragVisibilityClasses}${this.optionalColumnsInitialState.length ? ' px-0 pb-0' : ' pb-0'}`;
  }

  ngOnInit() {
    if (this.localizationService) {
      this.submitErrorMessage$ = this.localizationService.localize('columnsChooserErrorMessage', {});
      this.lastColumnTitle$ = this.localizationService.localize('columnsChooserLastItemTitle', {});
      this.addAdditionalColumnsTitle$ = this.localizationService.localize('addAdditionalColumnsTitle', {});
      this.noAdditionalColumnsMessage$ = this.localizationService.localize('noAdditionalColumnsMessage', {});
      this.optionalColumnsLimitReachedMessage$ = this.localizationService.localize(
        'optionalColumnsLimitReachedMessage',
        {}
      );
      this.sortedColumnDescription$ = this.localizationService.localize('columnsChooserSortedColDescription', {});
      this.stickyColumnDescription$ = this.localizationService.localize('columnsChooserStickyColDescription', {});
      this.requiredColumnDescription$ = this.localizationService.localize('columnsChooserRequiredColDescription', {});
      this.sortedColumnTitle$ = this.localizationService.localize('columnsChooserSortedColWarning', {});
      this.stickyColumnTitle$ = this.localizationService.localize('columnsChooserStickyColWarning', {});
      this.requiredColumnTitle$ = this.localizationService.localize('columnsChooserRequiredColWarning', {});
      this.suggesterPlaceholder$ = this.localizationService.localize('customFieldsSuggesterPlaceholder', {});
      this.selectAllBtnTitle$ = this.localizationService.localize('columnsChooserSelectAll', {});
      this.clearAllBtnTitle$ = this.localizationService.localize('columnsChooserClearAll', {});
      this.resetBtnTitle$ = this.localizationService.localize('columnsChooserResetToDefault', {});
      this.cancelBtnTitle$ = this.localizationService.localize('columnsChooserCancel', {});
      this.saveBtnTitle$ = this.localizationService.localize('columnsChooserSave', {});
    }

    this.drawerInstanceRef
      .closeButtonClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.resetChangesInOptionalColumns());

    this.updateGridColumns();

    this.sortingField = this.grid.sort ? this.grid.sort[0]?.field : '';

    this.sortSub$ = this.grid.sortChange.pipe(map(e => e[0]?.field)).subscribe(field => {
      this.sortingField = field;
      const sortCol = this.columns.find(col => col.field === field);
      if (!sortCol) return;
      sortCol.hidden = false;
      this.drawerInstanceRef.setModified(true);
      this.cdr.detectChanges();
    });

    // `columnReorder` events are filtered to be sure that the event is not prevented by any of its subscribers
    // see `handleReordering` method in `FireflySharedGridDirective`
    this.reorderSub$ = this.grid.columnReorder.pipe(filter(event => !event.isDefaultPrevented())).subscribe(() => {
      requestAnimationFrame(() => {
        this.columns.forEach(col => {
          const match = (this.grid.columns as QueryList<ColumnComponent>).find(c => c.field === col.field);
          if (match) col.orderIndex = match.orderIndex;
        });
        this.columns.sort((a, b) => a.orderIndex - b.orderIndex);
        this.reset = false;
        this.cdr.detectChanges();
      });
    });
  }

  ngOnDestroy() {
    this.reorderSub$.unsubscribe();
    this.sortSub$.unsubscribe();
    this.iconTitleSub$.unsubscribe();
  }

  preventDragFn({ dragTarget, dropTarget }: PreventDragArgs<DraggableItem>): boolean {
    const dragTargetItem = dragTarget as DraggableItem;
    const dropTargetColumn = dropTarget as ColumnComponent;

    if (!dropTargetColumn) {
      return false;
    }

    return (
      dragTargetItem.disabled ||
      !dropTargetColumn.reorderable ||
      dropTargetColumn.sticky ||
      (typeof dropTargetColumn.cssClass === 'string' && dropTargetColumn.cssClass.includes('k-grid-content-sticky'))
    );
  }

  updateColumnsOrder(): void {
    const gridColumns = (this.grid.columns as QueryList<ColumnComponent>)
      .filter(
        (col: ColumnComponent) =>
          !!col.field && (!(col as unknown as { optional: boolean }).optional || col.includeInChooser)
      )
      .sort((a, b) => a.orderIndex - b.orderIndex);
    this.columns.forEach((col, i) => {
      if (col.orderIndex !== gridColumns[i].orderIndex) {
        col.orderIndex = gridColumns[i].orderIndex;
      }
    });
    this.drawerInstanceRef.setModified(true);
    this.reset = false;
  }

  updateGridColumns(): void {
    const columns = (this.grid.columns as QueryList<ColumnComponent>)
      .filter((c: ColumnComponent) => !!c.field)
      .map(column => {
        const clone = new ColumnComponent(column.parent);
        return Object.assign(clone, column);
      });

    this.columns = columns
      .filter(col => {
        const isOptional = this.isColumnOptional(col);
        return !isOptional || (isOptional && col.includeInChooser);
      })
      .sort((a, b) => a.orderIndex - b.orderIndex);

    this.columns.forEach(col => {
      if ((!col.hidden && this.isColumnSticky(col)) || !col.reorderable) {
        col.disabled = true;
        this.iconTitleSub$ = this.stickyColumnTitle$.subscribe(title => {
          col.iconTitle = title;
        });
      } else col.iconTitle = '';
    });

    this.optionalColumnsInitialState = columns.filter(col => {
      return (col as unknown as { optional: boolean }).optional;
    });

    this.optionalColumns = this.mapToSuggesterOptionalColumns(
      [...this.optionalColumnsInitialState].filter(col => !col.includeInChooser)
    );
  }

  isColumnDisabled(col: ColumnComponent): boolean {
    return this.isColumnRequired(col) || this.isColumnSorted(col) || this.isColumnSticky(col);
  }

  getCheckboxTitle(col: ColumnComponent): Observable<string> {
    if (this.isColumnSticky(col)) return this.stickyColumnTitle$;
    if (this.isColumnRequired(col)) return this.requiredColumnTitle$;
    if (this.isColumnSorted(col)) return this.sortedColumnTitle$;
    return of('');
  }

  getColumnDescription(col: ColumnComponent): Observable<string> {
    return zip(
      this.sortedColumnDescription$.pipe(mergeMap(value => iif(() => this.isColumnSorted(col), of(value), of('')))),
      this.stickyColumnDescription$.pipe(mergeMap(value => iif(() => this.isColumnSticky(col), of(value), of('')))),
      this.requiredColumnDescription$.pipe(mergeMap(value => iif(() => this.isColumnRequired(col), of(value), of(''))))
    ).pipe(map(descriptions => descriptions.filter(description => description).join(', ')));
  }

  private getColumnsDiff(columns: ({ optional: boolean } & ColumnComponent)[]): ColumnChooserTableSettings[] {
    const diff: ColumnChooserTableSettings[] = [];
    columns.forEach(col => {
      const match = this.grid.columns.find(c => col.field === (c as ColumnComponent).field);
      if (match) {
        if (col.hidden !== match.hidden && col.orderIndex === match.orderIndex) {
          col.orderIndex = match.orderIndex || match.leafIndex;
        }
        if (
          col.hidden !== match.hidden ||
          col.orderIndex !== match.orderIndex ||
          col.includeInChooser !== match.includeInChooser
        ) {
          diff.push(col as unknown as ColumnChooserTableSettings);
        }
      }
    });
    return diff;
  }

  cancelChanges() {
    this.resetChangesInOptionalColumns();
    this.close();
  }

  saveChanges(): void {
    // setTimeout added in case of selecting custom fields and immediately pressing Save
    setTimeout(() => {
      const columns = [
        ...this.columns,
        ...this.optionalColumnsToRemove.filter(col => {
          return this.columns.every(c => c.title !== col.title);
        })
      ] as ({ optional: boolean } & ColumnComponent)[];

      const diff = this.getColumnsDiff(columns);

      this.drawerInstanceRef.setDrawerLoadingState(LoadingType.Overlay);
      this.columnChooserService
        .updateTableSettings(this.gridId, diff, this.reset)
        .pipe(
          take(1),
          catchError(err => {
            this.showSubmitError = true;
            this.cdr.detectChanges();
            Log.error(err);
            return EMPTY;
          }),
          finalize(() => this.drawerInstanceRef.setDrawerLoadingState())
        )
        .subscribe(() => {
          this.drawerInstanceRef.setModified(false);
          columns.forEach(col => {
            const gridCol = this.grid.columns.find(c => (c as ColumnComponent).field === col.field);
            if (gridCol) {
              gridCol.hidden = col.hidden;
              gridCol.orderIndex = col.orderIndex;
              if (col.optional) {
                gridCol.includeInChooser = col.includeInChooser;
              }
            }
          });
          this.showSubmitError = false;
          this.optionalStagedColumns = [];
          this.optionalColumnsToRemove = [];
          this.close({ applyChanges: true });
        });
    }, 1);
  }

  toggleColumn(column: ColumnComponent): void {
    column.hidden = !column.hidden;
    this.drawerInstanceRef.setModified(true);
    this.reset = false;
  }

  selectAll(): void {
    this.drawerInstanceRef.setModified(true);
    this.columns.forEach(col => (col.hidden = false));
    this.reset = false;
  }

  clearAll(): void {
    this.drawerInstanceRef.setModified(true);
    this.columns.forEach(col => {
      if (this.isColumnDisabled(col)) return;
      col.hidden = true;
    });
    this.reset = false;
  }

  resetSettings(): void {
    this.optionalColumns = this.mapToSuggesterOptionalColumns([...this.optionalColumnsInitialState]);
    this.columns = this.columns.filter(col => {
      if (this.isColumnOptional(col)) {
        const match = this.initialGridColumns.find(c => col.field === (c as ColumnComponent).field);
        if (match) col.orderIndex = match.orderIndex;
        this.optionalColumnsToRemove.push(col);
        col.includeInChooser = false;
        col.hidden = true;
        return false;
      }
      return true;
    });
    this.columns.forEach(col => {
      const match = this.initialGridColumns.find(c => col.field === (c as ColumnComponent).field);
      if (match) {
        col.orderIndex = match.orderIndex;
        col.hidden = match.hidden;
      }
    });
    this.columns.sort((a, b) => a.orderIndex - b.orderIndex);
    this.drawerInstanceRef.setModified(true);
    this.reset = true;
  }

  showColumnsSuggester(suggester: FireflyMultipleSuggesterComponent) {
    if (!this.optionalColumns.length || this.selectedOptionalColumnsLimitReached) return;
    this.showAddColumnsLink = false;
    requestAnimationFrame(() => suggester.focusIn());
  }

  onSelect(selected: { title: string }) {
    const selectedCol = this.optionalColumnsInitialState.find(col => col.title === selected.title);
    if (selectedCol) {
      this.optionalStagedColumns = [...this.optionalStagedColumns, selectedCol];
    }
  }

  onSelectMany(selected: ColumnComponent[]) {
    const selectedCols = this.optionalColumnsInitialState.filter(col => selected.some(s => s.title === col.title));
    this.optionalStagedColumns = [...this.optionalStagedColumns, ...selectedCols];
  }

  onRemove(selected: ColumnComponent) {
    this.optionalStagedColumns = this.optionalStagedColumns.filter(col => col.title !== selected.title);
  }

  onRemoveMany(selected: ColumnComponent[]) {
    this.optionalStagedColumns = this.optionalStagedColumns.filter(col => selected.every(s => s.title !== col.title));
  }

  addOptionalColumns() {
    if (!this.optionalStagedColumns.length) {
      this.showAddColumnsLink = true;
      return;
    }
    this.optionalColumns = this.optionalColumns.filter(col =>
      this.optionalStagedColumns.every(s => s.title !== col.title)
    );
    this.optionalSelectedColumns = [...this.optionalSelectedColumns, ...this.optionalStagedColumns];
    this.optionalSelectedColumns.forEach((col, index) => {
      const match = this.grid.columns.find(c => c.title === col.title);
      if (match) match.includeInChooser = true;
      col.orderIndex = this.columns.length + index + 1;
      col.includeInChooser = true;
      col.hidden = false;
    });
    this.columns = [...this.columns, ...this.optionalSelectedColumns];
    this.drawerInstanceRef.setModified(true);
    this.selectedOptionalColumns = [];
    this.optionalStagedColumns = [];
    this.showAddColumnsLink = true;
    this.reset = false;
  }

  removeOptionalColumn($event: MouseEvent, optionalCol: ColumnComponent) {
    $event.preventDefault();
    optionalCol.hidden = true;
    optionalCol.includeInChooser = false;
    this.columns = this.columns.filter(col => col.title !== optionalCol.title);
    const index = this.optionalColumnsToRemove.findIndex(col => col.title === optionalCol.title);
    index >= 0 ? (this.optionalColumnsToRemove[index] = optionalCol) : this.optionalColumnsToRemove.push(optionalCol);
    this.optionalColumns = this.mapToSuggesterOptionalColumns(
      this.optionalColumnsInitialState.filter(col => this.columns.every(c => c.title !== col.title))
    );
    this.updateColumnsOrder();
    this.drawerInstanceRef.setModified(true);
  }

  private mapToSuggesterOptionalColumns(columns: ColumnComponent[]): { title: string }[] {
    return columns.map(({ title }) => ({ title }));
  }

  private isColumnRequired(col: ColumnComponent): boolean {
    return !col.includeInChooser && !(col as unknown as { optional: boolean }).optional;
  }

  private isColumnSorted(col: ColumnComponent): boolean {
    return !col.hidden && col.field === this.sortingField;
  }

  private isColumnSticky(col: ColumnComponent): boolean {
    return isColumnSticky(col);
  }

  private isColumnOptional(col: ColumnComponent): boolean {
    return Object.hasOwnProperty.call(col, 'optional');
  }

  private resetChangesInOptionalColumns() {
    // setTimeout added in case of selecting custom fields and immediately pressing Cancel
    // Drag-n-drop will work incorrectly without setting match.includeInChooser to true thus it should be reset in this method
    setTimeout(() => {
      this.optionalSelectedColumns.forEach(col => {
        const match = this.grid.columns.find(c => c.title === col.title);
        if (match) match.includeInChooser = false;
      });
      this.optionalColumnsToRemove = [];
    }, 1);
  }
}
