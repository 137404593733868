export type ActivistFilter = {
  id: ActivistType;
  text: string;
};

export enum ActivistType {
  Often = 'Often',
  Occasional = 'Occasional',
  None = ''
}
