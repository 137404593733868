<a class="link link-main ca-bottom-nav" [class.ca-bottom-nav-with-title]="showApplicationTitle" tabindex="0">
  <div
    *ngIf="!mini"
    class="user-select-none d-flex flex-column"
    data-automation-id="bottom-nav"
    @fadeWidth
    @fadeOpacity
  >
    <div class="ca-bottom-nav-logo"></div>
    <span *ngIf="showApplicationTitle" class="text-neutral ca-bottom-nav-title">
      {{ appTitle || fallbackTitle }}
    </span>
    <span class="ca-bottom-nav-text">© {{ currentYear }} S&P Global. All Rights Reserved</span>
  </div>
  <i class="f-i f-i-sm" [ngClass]="mini ? 'f-i-chevron-right' : 'f-i-chevron-left'"></i>
</a>
