import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  Host,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { ColumnBase, ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FireflyColumnChooserService } from '../../column-chooser/column-chooser.service';
import { Breakpoint } from '../../utils';
import { FireflyGridResizingService } from './grid-resizing.service';

@Directive({
  selector: '[fKendoInteractiveDetailsGrid]'
})
export class FireflyKendoDetailsGridInteractiveDirective implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  animationRequestColumnChooser?: number;
  animationRequestReorder?: number;
  animationRequestColgroup?: number;

  get isMobile(): boolean {
    return window.innerWidth < Breakpoint.Sm;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @Host() private hostGrid: GridComponent,
    @Optional() @SkipSelf() private parentGrid: GridComponent,
    private columnChooserService: FireflyColumnChooserService,
    private gridResizingService: FireflyGridResizingService
  ) {}

  ngOnInit() {
    if (!this.parentGrid) return;

    this.hostGrid.resizable = true;
    this.hostGrid.reorderable = true;

    requestAnimationFrame(() => {
      this.reorderChildGridColumns();
      this.resizeChildGridColumns();
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.listenToColumnsReorder();
    this.listenToColgroupChanges();
    this.listenToColumnChooserChanges();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.cancelAllAnimationRequests();
  }

  private listenToColumnChooserChanges() {
    this.columnChooserService.applyChangesObs.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.animationRequestColumnChooser = requestAnimationFrame(() => {
        this.reorderChildGridColumns();
        this.resizeChildGridColumns();
        this.cdr.detectChanges();
      });
    });
  }

  private listenToColumnsReorder() {
    // `columnReorder` events are filtered to be sure that the event is not prevented by any of its subscribers
    // see `handleReordering` method in `FireflySharedGridDirective`
    this.parentGrid.columnReorder
      .pipe(
        filter(event => !event.isDefaultPrevented()),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.animationRequestReorder = requestAnimationFrame(() => {
          this.reorderChildGridColumns();
          this.cdr.detectChanges();
        });
      });
  }

  private listenToColgroupChanges() {
    this.gridResizingService
      .onGridColumnsChanges(this.parentGrid)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.isMobile) return;
        this.animationRequestColgroup = requestAnimationFrame(() => {
          this.resizeChildGridColumns();
          this.cdr.detectChanges();
        });
      });
  }

  private resizeChildGridColumns() {
    this.parentGrid.columns.forEach(column => {
      const childGridColumn = this.getChildGridColumn(column);
      if (childGridColumn) {
        childGridColumn.width = column.width;
        childGridColumn.hidden = column.hidden;
      }
    });
  }

  private reorderChildGridColumns() {
    if (!this.parentGridIsReordered) return;

    const columnOrderMap = new Map<number, ColumnBase>();
    this.parentGrid.columns.forEach(column => columnOrderMap.set(column.orderIndex, column));
    const sortedOrderIndexes = [...columnOrderMap.keys()].sort((a, b) => (a > b ? 1 : -1));

    sortedOrderIndexes.forEach(orderIndex => {
      const column = columnOrderMap.get(orderIndex) as ColumnBase;
      const childGridColumn = this.getChildGridColumn(column);

      if (!childGridColumn || childGridColumn?.orderIndex === column.orderIndex) return;
      const before = childGridColumn.orderIndex > column.orderIndex;
      this.hostGrid.reorderColumn(childGridColumn, column.orderIndex, { before });
    });
  }

  private getChildGridColumn(column: ColumnBase): ColumnBase | undefined {
    const field = (column as ColumnComponent).field;
    let childGridColumn;

    if (field) {
      childGridColumn = this.hostGrid.columns.find(col => {
        return (col as ColumnComponent).field === (column as ColumnComponent).field;
      });
    } else {
      childGridColumn = this.parentGridIsReordered
        ? this.getColumnByLeafIndex(column.orderIndex, this.hostGrid)
        : this.hostGrid.columns.get(column.leafIndex);
    }

    return childGridColumn;
  }

  private getColumnByLeafIndex(index: number, grid: GridComponent): ColumnBase | undefined {
    return grid.columns.find(col => col.leafIndex === index);
  }

  private get parentGridIsReordered() {
    return this.parentGrid.columns.some(col => col.orderIndex > 0);
  }

  private cancelAllAnimationRequests() {
    [this.animationRequestColumnChooser, this.animationRequestReorder, this.animationRequestColgroup].forEach(id => {
      id !== undefined && cancelAnimationFrame(id);
    });
  }
}
